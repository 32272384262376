import { Button, Divider, Form, Input, message, Modal, Select, Space, TableProps, Tag, Upload } from 'antd'
import { UploadOutlined, EyeOutlined, MoneyCollectOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { IUser } from '../../models/user.model'
import { useDispatch } from 'react-redux'
import { useLoading } from '../../context/LoadingContext'
import { getSession } from '../../redux/slices/authSlice'
import { toastError, toastSuccess } from '../../utils/toast'
import { CheckOutlined } from '@ant-design/icons'
import { apiConnectTransaction, apiGetListCompanies } from '../../api'
import defaultParams from '../../assets/constants/default_params'
import { ICompany } from '../../models/company.modal'
import { IConnectTransaction } from '../../models/transaction.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import * as XLSX from 'xlsx'
import CustomTable from '../../components/table'
import { kitSims } from '../../mocks/kit-sim'
import moment from 'moment'
import { DETAIL_TRANSACTION, KIT_SIM } from '../../assets/constants/route_path'
import { Link } from 'react-router-dom'

export default function Connect() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [orderTopups, setOrderTopups] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [modalStatus, setModalStatus] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [admin, setAdmin] = useState<IUser>()
    const dispatch = useDispatch()
    const { setIsLoading } = useLoading()
    const [formConnect] = Form.useForm()
    const [typeConnect, setTypeConnect] = useState<Number>(1)

    const [fileExcel, setFileExcel] = useState<any>()
    const [fileList, setFileList] = useState([])

    const fetchOrder = useCallback(async () => {
        try {
            setIsLoading(true)
            const respOrder = { data: kitSims }
            console.log(currentPage, pageSize)
            if (respOrder.data === null) {
                setOrderTopups({})
                return
            }
            setOrderTopups(respOrder.data)

            const respCompanies = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (respCompanies.data === null) {
                return
            }

            const resp = { total: respOrder.data.total, orders: [] as any }
            const listOrders = respOrder.data.orders
            const listCompanies = respCompanies.data.companies
            for (let i = 0; i < listOrders.length; i++) {
                const order = listOrders[i]
                const companyInfo = listCompanies.find((company: ICompany) => company.id === order.company_id)
                resp.orders.push({
                    ...order,
                    companyName: companyInfo?.name,
                })
            }
            setOrderTopups(resp)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize])

    const fetchCompanies = useCallback(async () => {
        try {
            const respCompany = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (respCompany.data?.companies === null) {
                return
            }
            const compOptions = respCompany.data.companies.map((item: ICompany) => ({
                label: `${item.id} - ${item.name}`,
                value: item.id
            }))
            setCompanyOptions(compOptions)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchData = useCallback(async () => {
        try {
            const sessionResp = await dispatch(getSession()).unwrap()
            setAdmin(sessionResp.data)
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra! Vui lòng kiểm tra lại')
        }
    }, [dispatch])

    useEffect(() => {
        fetchOrder()
        fetchCompanies()
        fetchData()
    }, [fetchOrder, fetchCompanies, fetchData])

    const handleConnect = async (values: IConnectTransaction) => {
        if (typeConnect !== 1) {
            return
        }

        try {
            setIsLoading(true)
            values.supervisor = admin?.full_name || admin?.username
            const resp = await apiConnectTransaction(values)
            if (resp.status === 200) {
                toastSuccess('Thêm đấu nối mới thành công!')
                formConnect.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleUploadFile = async () => {
        if (!fileExcel) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", fileExcel.file)
            toastSuccess('Nạp file excel thành công!')
            setFileExcel({})
            setDataExcel(null)
            setFileList([])
            formConnect.resetFields()
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }
    const checkUploadFile = (file: any) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
        if (!isExcel) {
            message.error('Vui lòng upload file dạng excel!')
            return Upload.LIST_IGNORE
        }
        return false
    }
    const handleChangeFile = (value: any) => {
        if (value.fileList?.length > 0) {
            handleFileUpload(value.file)
        }
        setFileList(value.fileList)
        setFileExcel(value)
    }

    const [dataExcel, setDataExcel] = useState<any>(null)
    const [previewFileExcel, setPreviewFileExcel] = useState(false)

    const handleFileUpload = (file: any) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const workbook = XLSX.read(event.target?.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)
            setDataExcel(sheetData)
        }

        reader.readAsBinaryString(file)
    }

    const listColumnExcel = dataExcel?.length > 0 ? Object.keys(dataExcel[0])?.map(key => {
        return ({
            key: key,
            title: key,
            dataIndex: key,
        })
    }) : []

    const previewExcelColumns: TableProps<any>['columns'] = [
        { key: 'id', title: 'STT', render: (_text, _record, index) => index + 1 },
        ...listColumnExcel
    ]

    const orderColumns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo',
            dataIndex: 'ownerBy',
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'companyName',
            title: 'Công ty',
            dataIndex: 'companyName',
        },
        {
            key: 'total',
            title: 'Tổng số đấu nối',
            dataIndex: 'total',
        },
        {
            key: 'totalSuccess',
            title: 'Tổng số thành công',
            dataIndex: 'totalSuccess',
        },
        {
            key: 'totalFail',
            title: 'Tổng số thất bại',
            dataIndex: 'totalFail',
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    <Link to={KIT_SIM + DETAIL_TRANSACTION + `?orderId=${data.orderId}`}>
                        <Button
                            icon={<EyeOutlined />}
                            style={{ background: '#289e45', color: 'white' }}
                        >
                            Chi tiết
                        </Button>
                    </Link>
                    <Button
                        disabled={data.status !== 'CREATED'}
                        onClick={() => setModalStatus({ data, isOpen: true })}
                        icon={<MoneyCollectOutlined />}
                        type='primary'
                    >
                        Đấu nối
                    </Button>
                </Space>
            ),
        },
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'COMPLETED') return <Tag style={style} color="#9b9b05">Hoàn thành</Tag>
        if (state === 'CANCELLED') return <Tag style={style} color="#c93c3c">Đã huỷ bỏ</Tag>
        if (state === 'CREATED') return <Tag style={style} color="#2faac6">Đã tạo</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#ff6f00">Đang nạp excel</Tag>
    }

    const handleTopupOrderId = async () => {
        try {
            setIsLoading(true)
            toastSuccess('Đấu nối thành công!')
            setModalStatus({ data: null, isOpen: false })
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }
    const handleCloseModalTopupOrderId = () => {
        setModalStatus({ data: null, isOpen: false })
    }

    return (
        <div>
            <div>
                <Form form={formConnect} onFinish={typeConnect === 1 ? handleConnect : handleUploadFile}>
                    <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                        <Form.Item style={{ flex: 1 }} name='type' label='Loại đấu nối'>
                            <Select
                                value={typeConnect}
                                onChange={(value) => setTypeConnect(value)}
                                defaultValue={1}
                                options={[
                                    { label: 'Đấu nối đơn', value: 1 },
                                    { label: 'Đấu nối theo lô', value: 2 },
                                ]} />
                        </Form.Item>
                        <Form.Item style={{ flex: 1 }} name='company_id' label='Công ty'
                            rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                placeholder="Chọn công ty"
                                options={companyOptions}
                                filterOption={filterOptionByLabel}
                            />
                        </Form.Item>
                        <Form.Item style={{ flex: 1 }} name='supervisor' label='Nhân sự đấu nối'>
                            <Input disabled placeholder={admin?.full_name || admin?.username} />
                        </Form.Item>
                    </div>
                    {typeConnect === 1 ? (
                        <>
                            <Form.Item name='message' label='Cú pháp' tooltip={<div>
                                <p style={{ fontSize: 10 }}><b>Cú pháp: </b>MSISDN SERIAL_SIM SĐT CHU_KY</p>
                                <p style={{ fontSize: 10 }}><b>VD: </b>1699449028 8984048000934412211 0338588826 1</p>
                            </div>}
                                rules={[{ required: true, message: 'Vui lòng nhập cú pháp!' }]}>
                                <Input />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item name='file' label={<span>Đăng tải File Excel ( {<Link to={""}>Template</Link>} )</span>}
                                tooltip={<div>
                                    <p style={{ fontSize: 10 }}><b>Định dạng:</b> Msisdn, SerialSim, </p>
                                    <p style={{ fontSize: 10 }}><b>Tên sheet:</b> Sheet1, </p>
                                    <p style={{ fontSize: 10 }}><b>Tối đa:</b> 1.000 sim</p>
                                </div>}
                                rules={[{ required: true, message: 'Vui lòng đăng tải file Excel!' }]}>
                                <Space style={{ display: 'flex' }}>
                                    <Upload
                                        maxCount={1}
                                        fileList={fileList}
                                        onChange={handleChangeFile}
                                        beforeUpload={checkUploadFile}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                    {dataExcel && <Button onClick={() => setPreviewFileExcel(true)} type='primary'>Preview</Button>}
                                </Space>
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} name='description' label='Mô tả'
                                rules={[{ required: true, message: 'Vui lòng nhập mô tả!' }]}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                    <div style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" icon={<CheckOutlined />} style={{ background: '#008965', color: 'white' }}>
                            <b>Xác nhận</b>
                        </Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <CustomTable
                namePage='đơn hàng'
                columns={orderColumns}
                dataSource={orderTopups.orders}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={orderTopups.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Preview File Excel'
                open={previewFileExcel}
                onCancel={() => setPreviewFileExcel(false)}
                footer={[]}
            >
                {dataExcel && (
                    <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        <CustomTable
                            columns={previewExcelColumns}
                            dataSource={dataExcel}
                            total={dataExcel.length}
                            isHiddenPagination
                        />
                    </div>
                )}
            </Modal>
            <Modal title='Đấu nối' open={modalStatus.isOpen}
                onCancel={handleCloseModalTopupOrderId}
                footer={[
                    <Button onClick={handleTopupOrderId}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTopupOrderId}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nạp tiền cho đơn hàng <b>{modalStatus.data?.orderId}</b></p>
                <Divider />
            </Modal>
        </div>
    )
}

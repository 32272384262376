import { Line } from 'react-chartjs-2'

export default function ChartTotalExpiredByMonth({ extended, expired, filtered }: any) {
    const percentExpired = {} as any
    const percentFiltered = {} as any

    Object.keys(filtered).forEach(key => {
        const totalExtended = extended[key] || 0
        const totalExpired = expired[key] || 0
        const totalFiltered = filtered[key] || 0

        if (totalExpired > 0) {
            percentExpired[key] = ((totalExtended / totalExpired) * 100).toFixed(2)
        } else {
            percentExpired[key] = 0
        }
        if (totalFiltered > 0) {
            percentFiltered[key] = ((totalExtended / totalFiltered) * 100).toFixed(2)
        } else {
            percentFiltered[key] = 0
        }
    })

    const data = {
        labels: Object.keys(extended),
        datasets: [
            {
                type: 'bar',
                label: 'Gia hạn/KH hết hạn',
                data: Object.values(percentExpired),
                borderWidth: 2,
            },
            {
                type: 'line',
                label: 'Gia hạn/KH có dữ liệu',
                data: Object.values(percentFiltered),
                borderWidth: 2,
            },
        ],
    } as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: 'Tỷ lệ gia hạn/ KH hết hạn/ KH có dữ liệu',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => `${value}%`,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={data} options={options} />
    )
}

import { Line } from "react-chartjs-2"

export default function ChartType({ totalPeriodByMonth, startMonth = 1, endMonth = 12 }: any) {
    const months = {} as any
    const monthExisted = {} as any

    totalPeriodByMonth.forEach((item: any) => {
        for (let i = Number(startMonth); i <= Number(endMonth); i++) {
            if (!months[`T${i}`]) {
                months[`T${i}`] = 0
            }
            months[`T${i}`] += item.values[`T${i}`]
        }
        for (let i = Number(startMonth); i <= Number(endMonth); i++) {
            if (months[`T${i}`] > 0) {
                monthExisted[`T${i}`] = months[`T${i}`]
            }
        }
    })

    const percentageData = totalPeriodByMonth.map((item: any) => {
        const percentages = Object.entries(monthExisted).map(([key, value]: any) => {
            return ((item.values[key] / value) * 100).toFixed(2)
        })
        return {
            period: item.period,
            values: percentages,
        }
    })

    const data = {
        labels: Object.keys(monthExisted),
        datasets: percentageData.map((item: any) => ({
            label: `${item.period} tháng`,
            data: Object.values(item.values),
            borderWidth: 2,
        })),
    } as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Tỷ lệ đấu nối gói theo chu kỳ',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => `${value}%`.replace('.00', ''),
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={data} options={options} />
    )
}

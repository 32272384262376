import dataJson from '../../mocks/dashboard.json'
import { DatePicker, Form, ConfigProvider, Select, Space, Button } from 'antd'
import { ExceptionOutlined, DashboardOutlined, DollarOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { formatCurrency, formatNumber } from '../../utils/string-utils'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import { useCallback, useEffect, useState } from 'react'
import ChartExpiredByDay from '../../components/chart/ChartExpiredByDay'
import ChartExtendedByMonth from '../../components/chart/ChartExtendedByMonth'
import ChartNeedExpiredByMonth from '../../components/chart/ChartNeedExpiredByMonth'
import ChartTotalExpiredByMonth from '../../components/chart/ChartTotalExpiredByMonth'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListCompanies } from '../../api'
import defaultParams from '../../assets/constants/default_params'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { aggregatePerMonth } from './method'

const { RangePicker } = DatePicker

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')

export default function DashboardGiaHan() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [data, setData] = useState<any>({})
    const [search, setSearch] = useState<any>({})
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchCompany = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCompanies = await apiGetListCompanies({
                limit: defaultParams.MAX_LIMIT,
            })
            if (resCompanies.data?.companies) {
                const compOptions = resCompanies.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code
                }))
                setCompanyOptions(compOptions)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchCompany()
        let records = dataJson as any
        if (search.cpCode) {
            records = records.filter((item: any) => item.cpCode === search.cpCode)
        }
        if (search.startMonthExpired && search.endMonthExpired) {
            records = records.filter((item: any) => item.monthExpired >= search.startMonthExpired && item.monthExpired <= search.endMonthExpired)
        }
        if (search.startDate && search.endDate) {
            records = records.filter((item: any) => item.dayExpired >= search.startDate && item.dayExpired <= search.endDate)
        }
        if (search.inYear) {
            records = records.filter((item: any) => item.yearExpired === Number(search.inYear))
        }
        const { totalData, totalFilterExpireByMonth, totalExpiredByMonth, totalExpiredByDay, totalExtendedByMonth } = aggregatePerMonth(records)
        setData({ totalData, totalFilterExpireByMonth, totalExpiredByMonth, totalExpiredByDay, totalExtendedByMonth })
    }, [fetchCompany, search])

    const handleSearch = (values: any) => {
        const rangeMonthExpired = values?.monthExpired
        if (rangeMonthExpired) {
            const startMonthExpired = rangeMonthExpired[0] ? rangeMonthExpired[0]?.format('MM') : null
            const endMonthExpired = rangeMonthExpired[1] ? rangeMonthExpired[1]?.format('MM') : null
            values.startMonthExpired = startMonthExpired
            values.endMonthExpired = endMonthExpired
        }

        const rangeDate = values?.dateExpired
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }

        const year = values?.yearExpired
        if (year) {
            values.inYear = year?.format('YYYY')
        }

        setSearch({
            cpCode: values.cpCode,
            startMonthExpired: values.startMonthExpired,
            endMonthExpired: values.endMonthExpired,
            startDate: values.startDate,
            endDate: values.endDate,
            inYear: values.inYear,
        })
    }

    const handleClearSearch = () => {
        setSearch({})
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', marginBottom: 20 }}
                form={formSearch} onFinish={handleSearch} id="searchForm" layout='vertical' autoComplete='off'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap', gap: 20, width: '100%' }}>
                    <Form.Item name='cpCode' label={<b>Đối tác</b>}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            disabled
                            placeholder="ZesTech"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <ConfigProvider locale={vi_VN}>
                        <Form.Item name='monthExpired' label='Tháng hết hạn'>
                            <RangePicker picker="month" />
                        </Form.Item>
                        <Form.Item name='dateExpired' label='Ngày hết hạn'>
                            <RangePicker picker="date" />
                        </Form.Item>
                        <Form.Item name='yearExpired' label='Năm hết hạn'>
                            <DatePicker picker='year' />
                        </Form.Item>
                    </ConfigProvider>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 700, border: '2px dashed #ec4969', padding: '8px 20px', borderRadius: 10 }}>
                    <ExceptionOutlined /> SL sim hết hạn: <span style={{ fontSize: 20, color: '#ec4969' }}>{formatNumber(data.totalData?.totalExpired)}</span>
                </div>
                <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 700, border: '2px dashed #ec4969', padding: '8px 20px', borderRadius: 10 }}>
                    <DashboardOutlined /> Gia hạn: <span style={{ fontSize: 20, color: '#ec4969' }}>{formatNumber(data.totalData?.totalRenew)}</span>
                </div>
                <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 700, border: '2px dashed #ec4969', padding: '8px 20px', borderRadius: 10 }}>
                    <DollarOutlined /> Lợi nhuận từ GH: <span style={{ fontSize: 20, color: '#ec4969' }}>{formatCurrency(data.totalData?.totalExtendReturn)}</span>
                </div>
            </div>
            {Object.keys(data).length > 0 && (
                <>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                        <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                            <ChartTotalExpiredByMonth
                                extended={data.totalExtendedByMonth}
                                expired={data.totalExpiredByMonth}
                                filtered={data.totalFilterExpireByMonth}
                            />
                        </div>
                        <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                            <ChartNeedExpiredByMonth
                                totalSim={data.totalExpiredByMonth}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                        <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                            <ChartExpiredByDay
                                expired={data.totalExpiredByDay}
                            />
                        </div>
                        <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                            <ChartExtendedByMonth
                                totalSim={data.totalExtendedByMonth}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

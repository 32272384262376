import { DayOfMonths, Days, Months, PackPeriod, TotalData } from "../../models/chart.model"

export const sortedObjectByKey = (data: any) => (
    Object.keys(data)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .reduce((acc: any, key) => {
            if (data[key] > 0) {
                acc[key] = data[key]
            }
            return acc
        }, {})
)

export const aggregatePerMonth = (data: any) => {
    let totalReturn = 0
    let totalConnect = 0
    let totalRenew = 0
    let totalExpired = 0
    let totalExtendReturn = 0
    const totalReturnByPack = {} as any
    const totalRevenueByPack = {} as any
    const totalConnectByMonth = {} as any
    const totalFilterByMonth = {} as any
    const totalPeriodByMonth = {} as any
    const totalExpiredByMonth = {} as any
    const totalExtendedByMonth = {} as any
    const totalFilterExpireByMonth = {} as any
    const totalExpiredByDay = {} as any
    const totalConnectByDay = {} as any
    data.forEach((item: any) => {
        const monthConnect = `T${item.monthConnect}`
        const dayConnect = `D${item.dayConnect}`
        const monthExpired = `T${item.monthExpired}`
        const dayExpired = `D${item.dayExpired}`
        const pack = item.package
        const period = item.numberOfMonths
        if (!totalConnectByMonth[monthConnect] && !totalFilterByMonth[monthConnect]) {
            totalConnectByMonth[monthConnect] = 0
            totalFilterByMonth[monthConnect] = 0
        }
        if (!totalExpiredByMonth[monthExpired] && !totalExtendedByMonth[monthExpired] && !totalFilterExpireByMonth[monthExpired]) {
            totalExpiredByMonth[monthExpired] = 0
            totalExtendedByMonth[monthExpired] = 0
            totalFilterExpireByMonth[monthExpired] = 0
        }
        if (!totalExpiredByDay[dayExpired]) {
            totalExpiredByDay[dayExpired] = 0
        }
        if (!totalConnectByDay[monthConnect]) {
            totalConnectByDay[monthConnect] = {}
        }
        if (!totalConnectByDay[monthConnect][dayConnect]) {
            totalConnectByDay[monthConnect][dayConnect] = 0
        }
        if (!totalReturnByPack[pack] && !totalRevenueByPack[pack]) {
            totalReturnByPack[pack] = 0
            totalRevenueByPack[pack] = 0
        }
        if (!totalPeriodByMonth[period]) {
            totalPeriodByMonth[period] = {}
        }
        if (!totalPeriodByMonth[period][monthConnect]) {
            totalPeriodByMonth[period][monthConnect] = 0
        }
        totalReturn += item.return
        totalReturnByPack[pack] += item.return
        totalRevenueByPack[pack] += item.revenue
        ++totalExpired
        ++totalConnectByMonth[monthConnect]
        ++totalConnectByDay[monthConnect][dayConnect]
        ++totalPeriodByMonth[period][monthConnect]
        ++totalExpiredByMonth[monthExpired]
        ++totalExpiredByDay[dayExpired]
        if (item.phoneFiltered > 0) {
            ++totalFilterByMonth[monthConnect]
        }
        if (item.phoneFiltered > 0) {
            ++totalFilterExpireByMonth[monthExpired]
        }
        switch (item.type) {
            case 'Đấu mới':
                ++totalConnect
                break
            case 'Gia hạn':
                ++totalRenew
                totalExtendReturn += item.return
                ++totalExtendedByMonth[monthExpired]
                break
            default:
                break
        }
    })

    let totalReturnPacks = []
    let totalRevenuePacks = []
    for (let i = 0; i < Object.keys(totalReturnByPack)?.length; i++) {
        totalReturnPacks.push({
            name: Object.keys(totalReturnByPack)[i],
            return: Number(Object.values(totalReturnByPack)[i]),
        })
        totalRevenuePacks.push({
            name: Object.keys(totalRevenueByPack)[i],
            revenue: Number(Object.values(totalRevenueByPack)[i]),
        })
    }

    let totalData = {
        totalReturn: totalReturn,
        totalConnect: totalConnect,
        totalRenew: totalRenew,
        totalExpired: totalExpired,
        totalExtendReturn: totalExtendReturn,
        totalReturnPacks: totalReturnPacks,
        totalRevenuePacks: totalRevenuePacks,
    }

    let periodValues = []
    const periodKey = Object.keys(totalPeriodByMonth)
    for (let i = 0; i < periodKey?.length; i++) {
        periodValues.push({
            period: periodKey[i],
            values: totalPeriodByMonth[periodKey[i]],
        })
    }

    return {
        totalData: totalData as TotalData,
        totalPeriodByMonth: periodValues as PackPeriod[],
        totalConnectByMonth: sortedObjectByKey(totalConnectByMonth) as Months,
        totalFilterByMonth: sortedObjectByKey(totalFilterByMonth) as Months,
        totalExpiredByMonth: sortedObjectByKey(totalExpiredByMonth) as Months,
        totalExtendedByMonth: sortedObjectByKey(totalExtendedByMonth) as Months,
        totalFilterExpireByMonth: sortedObjectByKey(totalFilterExpireByMonth) as Months,
        totalExpiredByDay: sortedObjectByKey(totalExpiredByDay) as Days,
        totalConnectByDay: totalConnectByDay as DayOfMonths,
    }
}
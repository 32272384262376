import dataJson from '../../mocks/dashboard.json'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { CategoryScale } from 'chart.js'
import { DatePicker, Form, ConfigProvider, Select, Space, Button } from 'antd'
import { ApiOutlined, DashboardOutlined, DollarOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { formatCurrency, formatNumber } from '../../utils/string-utils'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import ChartConnectByMonth from '../../components/chart/ChartConnectByMonth'
import ChartType from '../../components/chart/ChartType'
import ChartReturn from '../../components/chart/ChartReturn'
import { useCallback, useEffect, useState } from 'react'
import ChartConnectByDay from '../../components/chart/ChartConnectByDay'
import ChartTotalByMonth from '../../components/chart/ChartTotalByMonth'
import { apiGetListCompanies } from '../../api'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { aggregatePerMonth } from './method'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')

const { RangePicker } = DatePicker

Chart.register(CategoryScale, ChartDataLabels)

export default function DashboardDauNoi() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [data, setData] = useState<any>({})
    const [search, setSearch] = useState<any>({})
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchCompany = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCompanies = await apiGetListCompanies({
                limit: defaultParams.MAX_LIMIT,
            })
            if (resCompanies.data?.companies) {
                const compOptions = resCompanies.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code
                }))
                setCompanyOptions(compOptions)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchCompany()
        let records = dataJson as any
        if (search.cpCode) {
            records = records.filter((item: any) => item.cpCode === search.cpCode)
        }
        if (search.numberOfMonths) {
            records = records.filter((item: any) => item.numberOfMonths === search.numberOfMonths)
        }
        if (search.type) {
            records = records.filter((item: any) => item.type === search.type)
        }
        if (search.startMonth && search.endMonth) {
            records = records.filter((item: any) => item.monthConnect >= search.startMonth && item.monthConnect <= search.endMonth)
        }
        if (search.startDate && search.endDate) {
            records = records.filter((item: any) => item.dayConnect >= search.startDate && item.dayConnect <= search.endDate)
        }
        if (search.inYear) {
            records = records.filter((item: any) => item.yearConnect === Number(search.inYear))
        }
        const { totalConnectByMonth, totalFilterByMonth, totalConnectByDay, totalData, totalPeriodByMonth } = aggregatePerMonth(records)
        setData({ totalConnectByMonth, totalFilterByMonth, totalConnectByDay, totalData, totalPeriodByMonth })
    }, [fetchCompany, search])

    const handleSearch = (values: any) => {
        const rangeMonth = values?.monthConnect
        if (rangeMonth) {
            const startMonth = rangeMonth[0] ? rangeMonth[0]?.format('MM') : null
            const endMonth = rangeMonth[1] ? rangeMonth[1]?.format('MM') : null
            values.startMonth = startMonth
            values.endMonth = endMonth
        }

        const rangeDate = values?.dateConnect
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }

        const year = values?.yearConnect
        if (year) {
            values.inYear = year?.format('YYYY')
        }

        setSearch({
            cpCode: values.cpCode,
            type: values.type,
            numberOfMonths: values.numberOfMonths,
            startMonth: values.startMonth,
            endMonth: values.endMonth,
            startDate: values.startDate,
            endDate: values.endDate,
            inYear: values.inYear,
        })
    }

    const handleClearSearch = () => {
        setSearch({})
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', marginBottom: 20 }}
                form={formSearch} onFinish={handleSearch} id="searchForm" layout='vertical' autoComplete='off'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap', gap: 20, width: '100%' }}>
                    <Form.Item name='cpCode' label={<b>Đối tác</b>}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            disabled
                            placeholder="ZesTech"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <ConfigProvider locale={vi_VN}>
                        <Form.Item name='monthConnect' label='Tháng đấu nối'>
                            <RangePicker picker="month" />
                        </Form.Item>
                        <Form.Item name='dateConnect' label='Ngày đấu nối'>
                            <RangePicker picker="date" />
                        </Form.Item>
                        <Form.Item name='yearConnect' label='Năm đấu nối'>
                            <DatePicker picker='year' placeholder='2024' disabled />
                        </Form.Item>
                    </ConfigProvider>
                    <Form.Item name='type' label='Phân loại'>
                        <Select
                            style={{ width: 170 }}
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                { label: 'Đấu mới', value: 'Đấu mới' },
                                { label: 'Gia hạn', value: 'Gia hạn' },
                            ]} />
                    </Form.Item>
                    <Form.Item name='numberOfMonths' label='Chu kỳ'>
                        <Select
                            style={{ width: 170 }}
                            defaultValue={0}
                            options={[
                                { label: 'Tất cả', value: 0 },
                                { label: '1 tháng', value: 1 },
                                { label: '12 tháng', value: 12 },
                            ]} />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 700, border: '2px dashed #ec4969', padding: '8px 20px', borderRadius: 10 }}>
                    <ApiOutlined /> Đấu mới: <span style={{ fontSize: 20, color: '#ec4969' }}>{formatNumber(data.totalData?.totalConnect)}</span>
                </div>
                <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 700, border: '2px dashed #ec4969', padding: '8px 20px', borderRadius: 10 }}>
                    <DashboardOutlined /> Gia hạn: <span style={{ fontSize: 20, color: '#ec4969' }}>{formatNumber(data.totalData?.totalRenew)}</span>
                </div>
                <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 700, border: '2px dashed #ec4969', padding: '8px 20px', borderRadius: 10 }}>
                    <DollarOutlined /> Lợi nhuận: <span style={{ fontSize: 20, color: '#ec4969' }}>{formatCurrency(data.totalData?.totalReturn)}</span>
                </div>
            </div>
            {Object.keys(data).length > 0 && (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500, maxHeight: 400 }}>
                        <ChartConnectByMonth
                            connect={data.totalConnectByMonth}
                            filter={data.totalFilterByMonth}
                        />
                    </div>
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500, maxHeight: 400 }}>
                        <ChartType
                            totalPeriodByMonth={data.totalPeriodByMonth}
                            startMonth={search.startMonth}
                            endMonth={search.endMonth}
                        />
                    </div>
                    <div style={{ marginBottom: 40, flex: 1, maxWidth: 400, maxHeight: 400 }}>
                        <ChartReturn
                            totalReturnByPack={data.totalData?.totalReturnPacks}
                        />
                    </div>
                    <div style={{ marginBottom: 40, flex: 1, maxWidth: 400, maxHeight: 400 }}>
                        <ChartTotalByMonth
                            totalRevenuePack={data.totalData?.totalRevenuePacks}
                        />
                    </div>
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500, maxHeight: 400 }}>
                        <ChartConnectByDay
                            connect={data.totalConnectByDay}
                            startDate={search.startDate}
                            endDate={search.endDate}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

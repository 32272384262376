import { Doughnut } from 'react-chartjs-2'
import { formatCurrency } from '../../utils/string-utils'
import { useEffect, useState } from 'react'
import { PackReturn } from '../../models/chart.model';

export default function ChartReturn({ totalReturnByPack }: any) {
    const [totalReturn, setTotalReturn] = useState(0)

    useEffect(() => {
        const total = totalReturnByPack.reduce((total: any, current: PackReturn) => Number(total + current.return), 0)
        setTotalReturn(Number(total))
    }, [totalReturnByPack])

    const data = {
        labels: totalReturnByPack.map((item: PackReturn) => item.name),
        datasets: [
            {
                data: totalReturnByPack.map((item: PackReturn) => item.return),
                borderWidth: 2,
            },
        ],
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => `Lợi nhuận: ${formatCurrency(context.raw)}`,
                },
            },
            title: {
                display: true,
                text: 'Lợi nhuận các gói',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                formatter: (value: any) => formatCurrency(value),
                color: '#000',
                font: {
                    weight: 'bold',
                    size: 14,
                },
            },
        },
    } as any

    return (
        <Doughnut
            key={totalReturn}
            data={data} options={options} plugins={[{
                id: 'centerText',
                afterDraw: function (chart: any) {
                    const { ctx, width, height } = chart
                    ctx.restore()
                    ctx.font = `16px sans-serif`
                    ctx.textBaseline = 'middle'
                    const text = formatCurrency(totalReturn)
                    const textX = Math.round((width - ctx.measureText(text).width) / 2)
                    const textY = height / 2
                    ctx.fillText(text, textX, textY)
                    ctx.save()
                },
            }]} />
    )
}

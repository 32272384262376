export const GROUP_PERMISSIONS = {
    companies: 'companies',
    packages: 'packages',
    telegrams: 'telegrams',
    transactions: 'transactions',
    users: 'users',
    roles: 'roles',
    sim_passports: 'sim-passports',
    mbh_topups: 'mbh-topups',
    mbh_tkg_dashboard: 'mbh-tkg-dashboard',
    data_sponsor: 'data-sponsor',
    user_partner: 'user-partner',
    mbh_tkg_dashboard_partner: 'mbh-tkg-dashboard-partner',
}
import { Line } from 'react-chartjs-2'

export default function ChartConnectByDay({ connect, startDate = 1, endDate = 31 }: any) {
    const days = {} as any;
    for (let i = Number(startDate); i <= Number(endDate); i++) {
        days[`D${i}`] = 0;
    }

    const mergeArrays = Object.values(connect).map((item: any) => ({ ...days, ...item }))

    const data = {
        labels: Object.keys(days),
        datasets: mergeArrays.map((item: any, index: any) => ({
            label: Object.keys(connect)[index],
            data: Object.values(item),
            borderWidth: 2,
            hidden: mergeArrays.length - index > 2,
        })),
    } as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: 'SL sim đấu nối và SĐT khách hàng theo ngày',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={data} options={options} />
    )
}

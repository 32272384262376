import { Doughnut } from 'react-chartjs-2'
import { formatCurrency } from '../../utils/string-utils'
import { useEffect, useState } from 'react'

export default function ChartTotalByMonth({ totalRevenuePack }: any) {
    const [totalRevenue, setTotalRevenue] = useState(0)

    useEffect(() => {
        const total = totalRevenuePack.reduce((total: any, current: any) => Number(total + current.revenue), 0)
        setTotalRevenue(Number(total))
    }, [totalRevenuePack])

    const data = {
        labels: totalRevenuePack.map((item: any) => item.name),
        datasets: [
            {
                data: totalRevenuePack.map((item: any) => item.revenue),
                borderWidth: 2,
            },
        ],
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => formatCurrency(context.raw),
                },
            },
            title: {
                display: true,
                text: 'Doanh thu các gói',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                formatter: (value: any) => formatCurrency(value),
                color: '#000',
                font: {
                    weight: 'bold',
                    size: 14,
                },
            },
        },
    } as any

    return (
        <Doughnut
            key={totalRevenue}
            data={data}
            options={options}
            plugins={[{
                id: 'centerText',
                afterDraw: function (chart: any) {
                    const { ctx, width, height } = chart
                    ctx.restore()
                    ctx.font = `16px sans-serif`
                    ctx.textBaseline = 'middle'
                    const text = formatCurrency(totalRevenue)
                    const textX = Math.round((width - ctx.measureText(text).width) / 2)
                    const textY = height / 2
                    ctx.fillText(text, textX, textY)
                    ctx.save()
                },
            }]}
        />
    )
}

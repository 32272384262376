import React, { useState } from "react";
import { Layout, Menu, Row } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  TransactionOutlined,
  ApartmentOutlined,
  ShakeOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  UnlockOutlined,
  MoneyCollectOutlined,
  FileZipOutlined,
  AreaChartOutlined,
  AccountBookOutlined,
  ApiOutlined,
  CreditCardOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import LayoutHeader from "./header";
import LayoutFooter from "./footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import { useMatchedRoute } from "../../routers";
import IdleSessionTimeout from "./IdleSessionTimeout";
import BreadcrumbItem from "../../components/breadcrumb";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PermUtils from "../../utils/perm-utils";
import { COMPANY, KIT_SIM, DASHBOARD, DETAIL_PACKAGE, HISTORY, ORDER, OVERVIEW, PACKAGE, ROLE, ROOT_ACCOUNT, SIM_PASSPORT, TELEGRAM, TOP_UP, TRANSACTION, USER, CARD_SIM, CUSTOMER, DETAIL, CONNECT, SUBSCRIBER, REGISTER_ADDON, USER_PARTNER, DAU_NOI, GIA_HAN } from "../../assets/constants/route_path";
import { getDefaultAuthenticatedRoute } from "../../utils/app-utils";
import logoImg from '../../assets/images/logo.png';
import { APP_VERSION } from "../../config/constants";

const { Sider, Content } = Layout;

type MenuItem = {
  key: any;
  label: string | React.ReactNode;
  link?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  allowedfunctionids?: string[];
};

const buildMenuItem = ({
  key,
  label,
  link,
  icon,
  children,
  allowedfunctionids,
}: {
  label: string | React.ReactNode;
  key: string;
  link: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  allowedfunctionids?: string[];
}): MenuItem => {
  const item = {
    key,
    label,
    link,
    icon,
    children,
    allowedfunctionids,
  } as MenuItem;
  const hasChildren = children && children.length > 0;

  // Leaf item
  if (link && !hasChildren) {
    item.label = <Link to={link}>{label}</Link>;
  }

  return item;
};

const allItems: MenuItem[] = [
  buildMenuItem({
    key: "Dashboard",
    label: "Dashboard",
    link: DASHBOARD,
    icon: <AreaChartOutlined />,
    children: [
      {
        key: DASHBOARD + DAU_NOI,
        label: <Link to={DASHBOARD + DAU_NOI}>Đấu nối</Link>,
        link: DASHBOARD + DAU_NOI,
      },
      {
        key: DASHBOARD + GIA_HAN,
        label: <Link to={DASHBOARD + GIA_HAN}>Gia hạn</Link>,
        link: DASHBOARD + GIA_HAN,
      },
    ],
  }),
  buildMenuItem({
    key: "KitSim",
    label: "Quản lý đấu nối",
    link: KIT_SIM,
    icon: <ApiOutlined />,
    allowedfunctionids: [PermUtils.canGetListRoles, PermUtils.canGetListRoles],
    children: [
      {
        key: KIT_SIM + CONNECT,
        label: <Link to={KIT_SIM + CONNECT}>Đấu nối</Link>,
        link: KIT_SIM + CONNECT,
      },
      {
        key: KIT_SIM + ORDER,
        label: <Link to={KIT_SIM + ORDER}>Lịch sử đấu nối</Link>,
        link: KIT_SIM + ORDER,
      },
      {
        key: KIT_SIM + HISTORY,
        label: <Link to={KIT_SIM + HISTORY}>Lịch sử giao dịch</Link>,
        link: KIT_SIM + HISTORY,
      },
      {
        key: KIT_SIM + SUBSCRIBER,
        label: <Link to={KIT_SIM + SUBSCRIBER}>Quản lý đăng ký</Link>,
        link: KIT_SIM + SUBSCRIBER,
      },
    ],
  }),
  buildMenuItem({
    key: "CardSim",
    label: "Quản lý card sim",
    link: CARD_SIM,
    icon: <CreditCardOutlined />,
    allowedfunctionids: [PermUtils.canGetListRoles, PermUtils.canGetListRoles],
    children: [
      {
        key: CARD_SIM + ORDER,
        label: <Link to={CARD_SIM + ORDER}>Đơn hàng</Link>,
        link: CARD_SIM + ORDER,
      },
      {
        key: CARD_SIM + HISTORY,
        label: <Link to={CARD_SIM + HISTORY}>Lịch sử giao dịch</Link>,
        link: CARD_SIM + HISTORY,
      },
    ],
  }),
  buildMenuItem({
    key: "Customer",
    label: "Quản lý khách hàng",
    link: CUSTOMER,
    icon: <TeamOutlined />,
    allowedfunctionids: [PermUtils.canGetListRoles, PermUtils.canGetListRoles],
  }),
  buildMenuItem({
    key: "Company",
    label: "Quản lý đối tác",
    link: COMPANY,
    icon: <HomeOutlined />,
    allowedfunctionids: [PermUtils.canGetListCompanies],
    children: [
      {
        key: COMPANY,
        label: <Link to={COMPANY}>Đối tác</Link>,
        link: COMPANY,
        allowedfunctionids: [PermUtils.canGetListCompanies],
      },
      {
        key: COMPANY + USER_PARTNER,
        label: <Link to={COMPANY + USER_PARTNER}>Quản trị viên</Link>,
        link: COMPANY + USER_PARTNER,
        allowedfunctionids: [PermUtils.canGetListUserPartners],
      },
    ],
  }),
  buildMenuItem({
    key: "Package",
    label: "Quản lý gói cước",
    link: PACKAGE,
    icon: <ShakeOutlined />,
    allowedfunctionids: [],
    children: [
      {
        key: PACKAGE + OVERVIEW,
        label: <Link to={PACKAGE + OVERVIEW}>Tổng quan</Link>,
        link: PACKAGE + OVERVIEW,
        allowedfunctionids: [PermUtils.canGetListPackages],
      },
      {
        key: PACKAGE + DETAIL,
        label: <Link to={PACKAGE + DETAIL}>Đối tác</Link>,
        link: PACKAGE + DETAIL,
        allowedfunctionids: [PermUtils.canGetListPartnerPackages],
      },
    ],
  }),
  buildMenuItem({
    key: "Telegram",
    label: "Telegram",
    link: TELEGRAM,
    icon: <ApartmentOutlined />,
    allowedfunctionids: [PermUtils.canGetListTelegrams],
  }),
  buildMenuItem({
    key: "Transaction",
    label: "Giao dịch",
    link: TRANSACTION,
    icon: <TransactionOutlined />,
    allowedfunctionids: [PermUtils.canGetListTransactions],
  }),
  buildMenuItem({
    key: "TopUp",
    label: "Quản lý nạp tiền",
    link: TOP_UP,
    icon: <MoneyCollectOutlined />,
    allowedfunctionids: [],
    children: [
      {
        key: TOP_UP + OVERVIEW,
        label: <Link to={TOP_UP + OVERVIEW}>Tổng quan</Link>,
        link: TOP_UP + OVERVIEW,
        allowedfunctionids: [PermUtils.canCheckBalanceTopup],
      },
      {
        key: TOP_UP + ORDER,
        label: <Link to={TOP_UP + ORDER}>Đơn hàng</Link>,
        link: TOP_UP + ORDER,
        allowedfunctionids: [PermUtils.canGetListOrderTopups],
      },
      {
        key: TOP_UP + HISTORY,
        label: <Link to={TOP_UP + HISTORY}>Lịch sử giao dịch</Link>,
        link: TOP_UP + HISTORY,
        allowedfunctionids: [PermUtils.canGetListTransactionTopupsByOrderId],
      },
    ],
  }),
  buildMenuItem({
    key: "RootAccount",
    label: "Quản lý tài khoản gốc",
    link: ROOT_ACCOUNT,
    icon: <AccountBookOutlined />,
    allowedfunctionids: [],
    children: [
      {
        key: ROOT_ACCOUNT + OVERVIEW,
        label: <Link to={ROOT_ACCOUNT + OVERVIEW}>Tổng quan</Link>,
        link: ROOT_ACCOUNT + OVERVIEW,
      },
      {
        key: ROOT_ACCOUNT + ORDER,
        label: <Link to={ROOT_ACCOUNT + ORDER}>Đơn hàng</Link>,
        link: ROOT_ACCOUNT + ORDER,
        allowedfunctionids: [PermUtils.canGetListOrderTkgDashboard],
      },
      {
        key: ROOT_ACCOUNT + HISTORY,
        label: <Link to={ROOT_ACCOUNT + HISTORY}>Lịch sử giao dịch</Link>,
        link: ROOT_ACCOUNT + HISTORY,
        allowedfunctionids: [PermUtils.canGetListTransactionTkgDashboardByOrderId],
      },
      {
        key: ROOT_ACCOUNT + DETAIL_PACKAGE,
        label: <Link to={ROOT_ACCOUNT + DETAIL_PACKAGE}>Danh sách gói cước</Link>,
        link: ROOT_ACCOUNT + DETAIL_PACKAGE,
        allowedfunctionids: [PermUtils.canGetListPackagesTkgDashboard],
      },
    ],
  }),
  buildMenuItem({
    key: "RegisterAddon",
    label: "Quản lý data sponsor",
    link: REGISTER_ADDON,
    icon: <AccountBookOutlined />,
    allowedfunctionids: [],
    children: [
      {
        key: REGISTER_ADDON + OVERVIEW,
        label: <Link to={REGISTER_ADDON + OVERVIEW}>Tổng quan</Link>,
        link: REGISTER_ADDON + OVERVIEW,
        allowedfunctionids: [PermUtils.canCheckWalletBalancePostpaid],
      },
      {
        key: REGISTER_ADDON + ORDER,
        label: <Link to={REGISTER_ADDON + ORDER}>Đơn hàng</Link>,
        link: REGISTER_ADDON + ORDER,
        allowedfunctionids: [PermUtils.canGetListOrderDataSponsor],
      },
      {
        key: REGISTER_ADDON + HISTORY,
        label: <Link to={REGISTER_ADDON + HISTORY}>Lịch sử giao dịch</Link>,
        link: REGISTER_ADDON + HISTORY,
        allowedfunctionids: [PermUtils.canGetListTransactionDataSponsorByOrderId],
      },
      {
        key: REGISTER_ADDON + DETAIL_PACKAGE,
        label: <Link to={REGISTER_ADDON + DETAIL_PACKAGE}>Danh sách gói cước</Link>,
        link: REGISTER_ADDON + DETAIL_PACKAGE,
        allowedfunctionids: [PermUtils.canGetListPackagesSponsor],
      },
    ],
  }),
  buildMenuItem({
    key: "Passport",
    label: "Quản lý sim passport",
    link: SIM_PASSPORT,
    icon: <FileZipOutlined />,
    allowedfunctionids: [PermUtils.canGetListSimPassports],
  }),
  buildMenuItem({
    key: "User",
    label: "Quản trị viên",
    link: USER,
    icon: <UsergroupAddOutlined />,
    allowedfunctionids: [PermUtils.canGetListUsers],
  }),
  buildMenuItem({
    key: "Role",
    label: "Phân quyền",
    link: ROLE,
    icon: <UnlockOutlined />,
    allowedfunctionids: [PermUtils.canGetListRoles],
  }),
];

const isAuthorizedItem = (item: MenuItem, userFnIDs: string[]): boolean => {
  if (item?.allowedfunctionids && item?.allowedfunctionids?.length > 0) {
    return item?.allowedfunctionids?.some((id) => userFnIDs?.includes(id));
  }

  return true;
};

const authorizeMenuItems = (
  items: MenuItem[] = [],
  userFnIDs: string[] = [],
): MenuItem[] => {
  // Filter active items for origin array
  items = items?.filter((item) => isAuthorizedItem(item, userFnIDs));

  // Filter active items for nested items as children
  items?.forEach((item) => {
    if (item?.children && item?.children?.length > 0) {
      item.children = authorizeMenuItems(item.children, userFnIDs);
    }
  });

  return items;
};

const listAllActiveItems = (
  items: MenuItem[],
  currentPath: string | undefined,
): MenuItem[] => {
  const activeItems = items.filter(
    (item) => item.link && currentPath?.startsWith(item.link),
  );

  const childActiveItems = activeItems
    .map((item) => {
      if (item.children && item.children.length > 0) {
        return listAllActiveItems(item.children, currentPath);
      }

      return [];
    })
    .flat();

  return [...activeItems, ...childActiveItems];
};

const getLevelKeys = (items1: MenuItem[]) => {
  const key: Record<string, number> = {};
  const func = (items2: any, level = 1) => {
    items2.forEach((item: any) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const MainLayout = ({ children }: any) => {
  const authState = useSelector(authSelector);
  const { account } = authState;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const currentRoutePath = useMatchedRoute();
  const [collapsed, setCollapsed] = useState(false);
  console.log("account", account)
  // Avoid execute these functions multiple times
  const authorizedItems = authorizeMenuItems(allItems, account.listPermission);

  // Always expand all items
  const openedKeys = authorizedItems.map((item) => item.key);

  const activeItems = listAllActiveItems(authorizedItems, currentRoutePath);

  const activeItemLength = activeItems.length;
  const activeItemKeys = activeItems.map((item) => item.key);
  const selectedKey =
    activeItemLength === 0 ? null : activeItemKeys[activeItemLength - 1];

  const levelKeys = getLevelKeys(authorizedItems);
  const [stateOpenKeys, setStateOpenKeys] = useState(['2', '23']);

  const getSiderFooter = () => {
    return (
      <Row
        justify='space-between'
        align='middle'
        style={{ padding: "0 1rem " }}>
        <div style={{ fontSize: 14 }}>
          <SettingOutlined /> Phiên bản {APP_VERSION}
        </div>
        <div>
          <LeftOutlined />
        </div>
      </Row>
    );
  };

  const onOpenChange = (openKeys: any) => {
    const currentOpenKey = openKeys.find((key: any) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key: any) => key !== currentOpenKey)
        .findIndex((key: any) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_: any, index: any) => index !== repeatIndex)
          // remove current level all child
          .filter((key: any) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <Layout hasSider>
      <Sider
        breakpoint='md'
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={!collapsed ? getSiderFooter() : <RightOutlined />}>
        <Row
          justify='center'
          style={{ backgroundColor: "white", cursor: "pointer" }}
          onClick={() => navigate(getDefaultAuthenticatedRoute(account))}
        >
          {collapsed ? (
            <img
              src={logoImg}
              alt='Otek'
              style={{
                width: 65,
                height: 65,
                objectFit: "cover",
                objectPosition: "left",
                transform: "scale(0.8)",
              }}
            />
          ) : (
            <img
              src={logoImg}
              alt='Otek'
              style={{
                height: 65,
                transform: "scale(0.8)",
              }}
            />
          )}
        </Row>
        <Menu
          theme='dark'
          mode='inline'
          defaultOpenKeys={openedKeys}
          items={authorizedItems}
          selectedKeys={selectedKey}
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
        />
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: collapsed ? 80 : 250,
          transition: "margin-left 0.2s",
        }}>
        <LayoutHeader profile={account} collapsed={collapsed} />
        <div
          style={{
            padding: 12,
            marginTop: 64,
            paddingBottom: 0,
          }}>
          <BreadcrumbItem />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 100px)",
          }}>
          <Content
            style={{
              padding: 12,
              flexGrow: 1,
            }}>
            <div
              style={{
                minHeight: "100%",
                backgroundColor: "white",
              }}>
              {children}
            </div>
          </Content>
          <LayoutFooter />
        </div>
      </Layout>
      <IdleSessionTimeout account={authState.account} />
    </Layout>
  );
};

export default MainLayout;

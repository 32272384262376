import { permissionOptions } from "../assets/constants/default_options";
import { IBasePermission, IPermission } from "../models/role.model";
import { IUser } from "../models/user.model";
import _ from 'lodash';

export const convertListStringToPermission = (perms: string[]) => {
    return perms?.length > 0 ? perms.map((item: | string) => {
        const [action, resource] = item?.split('::')
        return { action, resource }
    }) : []
}

export const convertListPermissionToString = (perms: IPermission[] | undefined) => {
    return perms?.map((item: IPermission) => {
        return `${item.action}::${item.resource}`
    })
}

export const convertListGroupPermissionToString = (perms: IPermission[] | undefined, group: string) => {
    return perms?.map((perm: IPermission) => permissionOptions
        .map(item => item.group === group && item.value === `${perm.action}::${perm.resource}` && item.value)
        .filter(item => item)[0]
    ).filter(item => item)
}

export const convertListBasePermissionToOption = (perms: IBasePermission[]) => {
    return perms.map((item: IBasePermission) => ({
        label: item.description,
        value: `${item.action}::${item.resource}`,
        group: item.group,
        name: item.group_name,
    }))
}

// Companies
const canGetListCompanies = 'GET::/api/otek-dashboard/companies'
const canAddCompany = 'POST::/api/otek-dashboard/companies'
const canUpdateCompany = 'PATCH::/api/otek-dashboard/companies'
const canDeleteCompany = 'DELETE::/api/otek-dashboard/companies'

// Packages
const canGetListPackages = 'GET::/api/otek-dashboard/packages'
const canAddPackage = 'POST::/api/otek-dashboard/packages'
const canAddListPackages = 'POST::/api/otek-dashboard/packages/add-list-packages'
const canUpdatePackage = 'PATCH::/api/otek-dashboard/packages'
const canDeletePackage = 'DELETE::/api/otek-dashboard/packages'

// Partner Packages
const canGetListPartnerPackages = 'GET::/api/otek-dashboard/partner-packages'
const canAddPartnerPackage = 'POST::/api/otek-dashboard/partner-packages'
const canAddListPartnerPackages = 'POST::/api/otek-dashboard/partner-packages/add-list-partner-packages'
const canDeletePartnerPackage = 'DELETE::/api/otek-dashboard/partner-packages'
const canGetListPackagesTkgDashboard = 'GET::/api/otek-dashboard/partner-packages/get-list-packages-tkg-dashboard'
const canGetListPackagesSponsor = 'GET::/api/otek-dashboard/partner-packages/get-list-packages-sponsor'

// Telegrams
const canGetListTelegrams = 'GET::/api/otek-dashboard/telegrams'
const canAddTelegram = 'POST::/api/otek-dashboard/telegrams'
const canUpdateTelegram = 'PATCH::/api/otek-dashboard/telegrams'
const canDeleteTelegram = 'DELETE::/api/otek-dashboard/telegrams'

// Transactions
const canGetListTransactions = 'GET::/api/otek-dashboard/transactions'
const canRenewTransaction = 'PATCH::/api/otek-dashboard/transactions'
const canAddTransaction = 'POST::/api/otek-dashboard/transactions'
const canConnectTransaction = 'POST::/api/otek-dashboard/transactions/connect'

// Users
const canGetListUsers = 'GET::/api/otek-dashboard/users'
const canAddUser = 'POST::/api/otek-dashboard/users'
const canUpdateUser = 'PATCH::/api/otek-dashboard/users'
const canDeleteUser = 'DELETE::/api/otek-dashboard/users'

// Roles
const canGetListBasePermissions = 'GET::/api/otek-dashboard/base-permissions'
const canGetListRoles = 'GET::/api/otek-dashboard/roles'
const canAddRole = 'POST::/api/otek-dashboard/roles'
const canUpdateRole = 'PATCH::/api/otek-dashboard/roles'
const canDeleteRole = 'DELETE::/api/otek-dashboard/roles'

// Sim Passports
const canGetListSimPassports = 'GET::/api/otek-dashboard/sim-passports'
const canUpdateSimPassport = 'PATCH::/api/otek-dashboard/sim-passports'

// Mbh Topups
const canCheckBalanceTopup = 'GET::/api/otek-dashboard/mbh-topups/check-balance-topup'
const canGetListOrderTopups = 'GET::/api/otek-dashboard/mbh-topups/get-list-order-topups'
const canGetListTransactionTopupsByOrderId = 'GET::/api/otek-dashboard/mbh-topups/get-list-transaction-topups-by-order-id'
const canCheckTransactionTopupOnResource = 'GET::/api/otek-dashboard/mbh-topups/check-transaction-topup-on-resource'
const canCheckTransactionTopupOnAdapter = 'GET::/api/otek-dashboard/mbh-topups/check-transaction-topup-on-adapter'
const canUploadOrderTopupByExcel = 'POST::/api/otek-dashboard/mbh-topups/upload-order-topup-by-excel'
const canTopupByPhone = 'POST::/api/otek-dashboard/mbh-topups/topup-by-phone'
const canTopupByOrderId = 'POST::/api/otek-dashboard/mbh-topups/topup-by-order-id'

// Mbh Tkg Dashboard
const canGetDynamicPartnerOffer = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-dynamic-partner-offer'
const canGetListOrderTkgDashboard = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-list-order-tkg-dashboard'
const canGetListTransactionTkgDashboardByOrderId = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id'
const canCheckTransactionTkgOnResource = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource'
const canCheckTransactionTkgOnAdapter = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter'
const canUploadOrderTkgByExcel = 'POST::/api/otek-dashboard/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel'
const canTkgByPhone = 'POST::/api/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-phone'
const canTkgByOrderId = 'POST::/api/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-order-id'

// Data Sponsor
const canGetDynamicPartnerOfferDataSponsor = 'GET::/api/otek-dashboard/data-sponsor/get-dynamic-partner-offer-data-sponsor'
const canGetListOrderDataSponsor = 'GET::/api/otek-dashboard/data-sponsor/get-list-order-data-sponsor'
const canGetListTransactionDataSponsorByOrderId = 'GET::/api/otek-dashboard/data-sponsor/get-list-transaction-data-sponsor-by-order-id'
const canCheckTransactionDataSponsorOnResource = 'GET::/api/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-resource'
const canCheckTransactionDataSponsorOnAdapter = 'GET::/api/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-adapter'
const canUploadOrderDataSponsorByExcel = 'POST::/api/otek-dashboard/data-sponsor/upload-order-data-sponsor-by-excel'
const canDataSponsorByPhone = 'POST::/api/otek-dashboard/data-sponsor/data-sponsor-by-phone'
const canDataSponsorByOrderId = 'POST::/api/otek-dashboard/data-sponsor/data-sponsor-by-order-id'
const canCheckWalletBalancePostpaid = 'GET::/api/otek-dashboard/data-sponsor/check-wallet-balance-postpaid'

// User Partner
const canGetListUserPartners = 'GET::/api/otek-dashboard/user-partners/get-list-user-partners'
const canAddUserPartner = 'POST::/api/otek-dashboard/user-partners/add-user-partner'
const canUpdateUserPartner = 'PATCH::/api/otek-dashboard/user-partners/update-user-partner'
const canDeleteUserPartner = 'DELETE::/api/otek-dashboard/user-partners/delete-user-partner'

export const setUserPermissions = (user: IUser) => {
    const userPermission = user.listPermission

    // Companies
    user.canGetListCompanies = userPermission?.includes(canGetListCompanies)
    user.canAddCompany = userPermission?.includes(canAddCompany)
    user.canAddListPackages = userPermission?.includes(canAddListPackages)
    user.canUpdateCompany = userPermission?.includes(canUpdateCompany)
    user.canDeleteCompany = userPermission?.includes(canDeleteCompany)
    user.canCompanyManagement = user.canGetListCompanies
        || user.canAddCompany
        || user.canUpdateCompany
        || user.canDeleteCompany

    // Packages
    user.canGetListPackages = userPermission?.includes(canGetListPackages)
    user.canAddPackage = userPermission?.includes(canAddPackage)
    user.canAddListPackages = userPermission?.includes(canAddListPackages)
    user.canUpdatePackage = userPermission?.includes(canUpdatePackage)
    user.canDeletePackage = userPermission?.includes(canDeletePackage)
    user.canPackageManagement = user.canGetListPackages
        || user.canAddPackage
        || user.canAddListPackages
        || user.canUpdatePackage
        || user.canDeletePackage

    // Partner Packages
    user.canGetListPartnerPackages = userPermission?.includes(canGetListPartnerPackages)
    user.canAddPartnerPackage = userPermission?.includes(canAddPartnerPackage)
    user.canAddListPartnerPackages = userPermission?.includes(canAddListPartnerPackages)
    user.canDeletePartnerPackage = userPermission?.includes(canDeletePartnerPackage)
    user.canGetListPackagesTkgDashboard = userPermission?.includes(canGetListPackagesTkgDashboard)
    user.canGetListPackagesSponsor = userPermission?.includes(canGetListPackagesSponsor)
    user.canPartnerPackageManagement = user.canGetListPartnerPackages
        || user.canAddPartnerPackage
        || user.canAddListPartnerPackages
        || user.canDeletePartnerPackage
        || user.canGetListPackagesTkgDashboard
        || user.canGetListPackagesSponsor

    // Telegrams
    user.canGetListTelegrams = userPermission?.includes(canGetListTelegrams)
    user.canAddTelegram = userPermission?.includes(canAddTelegram)
    user.canUpdateTelegram = userPermission?.includes(canUpdateTelegram)
    user.canDeleteTelegram = userPermission?.includes(canDeleteTelegram)
    user.canTelegramManagement = user.canGetListTelegrams
        || user.canAddTelegram
        || user.canUpdateTelegram
        || user.canDeleteTelegram

    // Transactions
    user.canGetListTransactions = userPermission?.includes(canGetListTransactions)
    user.canRenewTransaction = userPermission?.includes(canRenewTransaction)
    user.canAddTransaction = userPermission?.includes(canAddTransaction)
    user.canConnectTransaction = userPermission?.includes(canConnectTransaction)
    user.canTransactionManagement = user.canGetListTransactions
        || user.canRenewTransaction
        || user.canAddTransaction
        || user.canConnectTransaction

    // Users
    user.canGetListUsers = userPermission?.includes(canGetListUsers)
    user.canAddUser = userPermission?.includes(canAddUser)
    user.canUpdateUser = userPermission?.includes(canUpdateUser)
    user.canDeleteUser = userPermission?.includes(canDeleteUser)
    user.canUserManagement = user.canGetListUsers
        || user.canAddUser
        || user.canUpdateUser
        || user.canDeleteUser

    // Roles
    user.canGetListBasePermissions = userPermission?.includes(canGetListBasePermissions)
    user.canGetListRoles = userPermission?.includes(canGetListRoles)
    user.canAddRole = userPermission?.includes(canAddRole)
    user.canUpdateRole = userPermission?.includes(canUpdateRole)
    user.canDeleteRole = userPermission?.includes(canDeleteRole)
    user.canRoleManagement = user.canGetListBasePermissions
        || user.canGetListRoles
        || user.canAddRole
        || user.canUpdateRole
        || user.canDeleteRole

    // Sim Passports
    user.canGetListSimPassports = userPermission?.includes(canGetListSimPassports)
    user.canUpdateSimPassport = userPermission?.includes(canUpdateSimPassport)
    user.canSimPassportManagement = user.canGetListSimPassports
        || user.canUpdateSimPassport

    //Topup
    user.canCheckBalanceTopup = userPermission?.includes(canCheckBalanceTopup)
    user.canGetListOrderTopups = userPermission?.includes(canGetListOrderTopups)
    user.canGetListTransactionTopupsByOrderId = userPermission?.includes(canGetListTransactionTopupsByOrderId)
    user.canCheckTransactionTopupOnResource = userPermission?.includes(canCheckTransactionTopupOnResource)
    user.canCheckTransactionTopupOnAdapter = userPermission?.includes(canCheckTransactionTopupOnAdapter)
    user.canUploadOrderTopupByExcel = userPermission?.includes(canUploadOrderTopupByExcel)
    user.canTopupByPhone = userPermission?.includes(canTopupByPhone)
    user.canTopupByOrderId = userPermission?.includes(canTopupByOrderId)
    user.canTopupManagement = user.canCheckBalanceTopup
        || user.canGetListOrderTopups
        || user.canGetListTransactionTopupsByOrderId
        || user.canCheckTransactionTopupOnResource
        || user.canCheckTransactionTopupOnAdapter
        || user.canUploadOrderTopupByExcel
        || user.canTopupByPhone
        || user.canTopupByOrderId

    //Tkg Dashboard
    user.canGetDynamicPartnerOffer = userPermission?.includes(canGetDynamicPartnerOffer)
    user.canGetListOrderTkgDashboard = userPermission?.includes(canGetListOrderTkgDashboard)
    user.canGetListTransactionTkgDashboardByOrderId = userPermission?.includes(canGetListTransactionTkgDashboardByOrderId)
    user.canCheckTransactionTkgOnResource = userPermission?.includes(canCheckTransactionTkgOnResource)
    user.canCheckTransactionTkgOnAdapter = userPermission?.includes(canCheckTransactionTkgOnAdapter)
    user.canUploadOrderTkgByExcel = userPermission?.includes(canUploadOrderTkgByExcel)
    user.canTkgByPhone = userPermission?.includes(canTkgByPhone)
    user.canTkgByOrderId = userPermission?.includes(canTkgByOrderId)
    user.canTkgDashboardManagement = user.canGetDynamicPartnerOffer
        || user.canGetListOrderTkgDashboard
        || user.canGetListTransactionTkgDashboardByOrderId
        || user.canCheckTransactionTkgOnResource
        || user.canCheckTransactionTkgOnAdapter
        || user.canUploadOrderTkgByExcel
        || user.canTkgByPhone
        || user.canTkgByOrderId

    //Data Sponsor
    user.canGetDynamicPartnerOfferDataSponsor = userPermission?.includes(canGetDynamicPartnerOfferDataSponsor)
    user.canGetListOrderDataSponsor = userPermission?.includes(canGetListOrderDataSponsor)
    user.canGetListTransactionDataSponsorByOrderId = userPermission?.includes(canGetListTransactionDataSponsorByOrderId)
    user.canCheckTransactionDataSponsorOnResource = userPermission?.includes(canCheckTransactionDataSponsorOnResource)
    user.canCheckTransactionDataSponsorOnAdapter = userPermission?.includes(canCheckTransactionDataSponsorOnAdapter)
    user.canUploadOrderDataSponsorByExcel = userPermission?.includes(canUploadOrderDataSponsorByExcel)
    user.canDataSponsorByPhone = userPermission?.includes(canDataSponsorByPhone)
    user.canDataSponsorByOrderId = userPermission?.includes(canDataSponsorByOrderId)
    user.canCheckWalletBalancePostpaid = userPermission?.includes(canCheckWalletBalancePostpaid)
    user.canDataSponsorManagement = user.canGetDynamicPartnerOffer
        || user.canGetListOrderDataSponsor
        || user.canGetListTransactionDataSponsorByOrderId
        || user.canCheckTransactionDataSponsorOnResource
        || user.canCheckTransactionDataSponsorOnAdapter
        || user.canUploadOrderDataSponsorByExcel
        || user.canDataSponsorByPhone
        || user.canDataSponsorByOrderId
        || user.canCheckWalletBalancePostpaid

    // User Partner
    user.canGetListUserPartners = userPermission?.includes(canGetListUserPartners)
    user.canAddUserPartner = userPermission?.includes(canAddUserPartner)
    user.canUpdateUserPartner = userPermission?.includes(canUpdateUserPartner)
    user.canDeleteUserPartner = userPermission?.includes(canDeleteUserPartner)
    user.canUserPartnerManagement = user.canGetListUserPartners
        || user.canAddUser
        || user.canUpdateUser
        || user.canDeleteUser
}

const hasAnyAuthority = (functions: string[], hasAnyFunctions: string[]) => {
    if (_.isEmpty(functions)) {
        return false;
    }
    if (hasAnyFunctions.length === 0) {
        return true;
    }
    return hasAnyFunctions.some(auth => functions.includes(auth));
};
const PermUtils = {
    // Companies
    canGetListCompanies,
    canAddCompany,
    canUpdateCompany,
    canDeleteCompany,

    // Packages
    canGetListPackages,
    canAddPackage,
    canAddListPackages,
    canUpdatePackage,
    canDeletePackage,

    // Partner Packages
    canGetListPartnerPackages,
    canAddPartnerPackage,
    canAddListPartnerPackages,
    canDeletePartnerPackage,
    canGetListPackagesTkgDashboard,
    canGetListPackagesSponsor,

    // Telegrams
    canGetListTelegrams,
    canAddTelegram,
    canUpdateTelegram,
    canDeleteTelegram,

    // Transactions
    canGetListTransactions,
    canRenewTransaction,
    canAddTransaction,
    canConnectTransaction,

    // Users
    canGetListUsers,
    canAddUser,
    canUpdateUser,
    canDeleteUser,

    // Roles
    canGetListBasePermissions,
    canGetListRoles,
    canAddRole,
    canUpdateRole,
    canDeleteRole,

    // Sim Passports
    canGetListSimPassports,
    canUpdateSimPassport,

    // Mbh Topups
    canCheckBalanceTopup,
    canGetListOrderTopups,
    canGetListTransactionTopupsByOrderId,
    canCheckTransactionTopupOnResource,
    canCheckTransactionTopupOnAdapter,
    canUploadOrderTopupByExcel,
    canTopupByPhone,
    canTopupByOrderId,

    // Mbh Tkg Dashboard
    canGetDynamicPartnerOffer,
    canGetListOrderTkgDashboard,
    canGetListTransactionTkgDashboardByOrderId,
    canCheckTransactionTkgOnResource,
    canCheckTransactionTkgOnAdapter,
    canUploadOrderTkgByExcel,
    canTkgByPhone,
    canTkgByOrderId,

    // Data Sponsor
    canGetDynamicPartnerOfferDataSponsor,
    canGetListOrderDataSponsor,
    canGetListTransactionDataSponsorByOrderId,
    canCheckTransactionDataSponsorOnResource,
    canCheckTransactionDataSponsorOnAdapter,
    canUploadOrderDataSponsorByExcel,
    canDataSponsorByPhone,
    canDataSponsorByOrderId,
    canCheckWalletBalancePostpaid,

    // User Partner
    canGetListUserPartners,
    canAddUserPartner,
    canUpdateUserPartner,
    canDeleteUserPartner,

    setUserPermissions,
    hasAnyAuthority
}

export default PermUtils
import { Bar } from 'react-chartjs-2'

export default function ChartExtendedByMonth({ totalSim }: any) {
    const data = {
        labels: Object.keys(totalSim),
        datasets: [
            {
                label: 'SL sim đã gia hạn',
                data: Object.values(totalSim),
                borderWidth: 2,
            },
        ],
    } as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: 'Số lượng sim gia hạn theo tháng',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Bar data={data} options={options} />
    )
}
